import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  IconButton,
  Button,
  Box,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Исправленный импорт
import { updateChannel } from "../../api";

const ChannelEditModal = ({ open, onClose, channel, onSave }) => {
  const [formData, setFormData] = useState({});
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open && channel) {
      setFormData({
        name: channel.name || "",
        description: channel.description || "",
        rss_url: channel.rss_url || "",
        url: channel.url || "",
        contact_phone: channel.contact_phone || "",
        contact_email: channel.contact_email || "",
        director: channel.director || "",
      });
    }
  }, [open, channel]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    setSaving(true);
    setError(null);

    try {
      const updatedChannel = await updateChannel(channel.id, formData);
      onSave(updatedChannel); // Передаем обновленные данные в родительский компонент
      onClose(); // Закрываем окно
    } catch (err) {
      console.error("Ошибка при сохранении данных канала:", err);
      setError(
        "Не удалось сохранить данные. Проверьте правильность введённых данных."
      );
    } finally {
      setSaving(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <Box>
        <DialogTitle
          sx={{           
            fontSize: "1.5rem", // Можно настроить размер текста заголовка
          }}
        >
          Редактировать канал
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </Box>

      <DialogContent sx={{ paddingBottom: 3, overflow: "hidden" }}>
        <Box display="flex" flexDirection="column" gap={2}>
          {error && <Box color="red">{error}</Box>}

          <TextField
            label="Название"
            name="name"
            value={formData.name || ""}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              sx: { fontSize: "0.9rem", color: "grey.700" },
            }}
            InputProps={{
              sx: { padding: "8px", fontSize: "0.9rem" }, // Уменьшаем высоту полей
            }}
          />
          <TextField
            label="Описание"
            name="description"
            value={formData.description || ""}
            onChange={handleChange}
            fullWidth
            multiline
            rows={3}
            InputLabelProps={{
              sx: { fontSize: "0.9rem", color: "grey.700" },
            }}
            InputProps={{
              sx: { padding: "8px", fontSize: "0.9rem" }, // Уменьшаем высоту
            }}
          />
          <TextField
            label="RSS URL"
            name="rss_url"
            value={formData.rss_url || ""}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              sx: { fontSize: "0.9rem", color: "grey.700" },
            }}
            InputProps={{
              sx: { padding: "8px", fontSize: "0.9rem" },
            }}
          />
          <TextField
            label="URL"
            name="url"
            value={formData.url || ""}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              sx: { fontSize: "0.9rem", color: "grey.700" },
            }}
            InputProps={{
              sx: { padding: "8px", fontSize: "0.9rem" },
            }}
          />
          <TextField
            label="Телефон"
            name="contact_phone"
            value={formData.contact_phone || ""}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              sx: { fontSize: "0.9rem", color: "grey.700" },
            }}
            InputProps={{
              sx: { padding: "8px", fontSize: "0.9rem" },
            }}
          />
          <TextField
            label="Почта"
            name="contact_email"
            value={formData.contact_email || ""}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              sx: { fontSize: "0.9rem", color: "grey.700" },
            }}
            InputProps={{
              sx: { padding: "8px", fontSize: "0.9rem" },
            }}
          />
          <TextField
            label="Руководитель"
            name="director"
            value={formData.director || ""}
            onChange={handleChange}
            fullWidth
            InputLabelProps={{
              sx: { fontSize: "0.9rem", color: "grey.700" },
            }}
            InputProps={{
              sx: { padding: "8px", fontSize: "0.9rem" },
            }}
          />
        </Box>
      </DialogContent>
      <Box display="flex" justifyContent="center" mt={2} mb={2}>
        <Button
          onClick={handleSave}
          disabled={saving}
          variant="contained"
          sx={{
            width: "200px",
            height: "45px",
            fontSize: "1rem",
            bgcolor: "primary.main",
            "&:hover": { bgcolor: "primary.dark" },
          }}
        >
          {saving ? (
            <Skeleton variant="rectangular" width={80} height={24} />
          ) : (
            "Сохранить"
          )}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ChannelEditModal;
