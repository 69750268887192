import React from "react";
import { Box, Typography } from "@mui/material";

const PublicationsHeader = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      mb={3}
      sx={{
        borderBottom: "2px solid #e0e0e0", // Линия под заголовком
        pb: 1,
      }}
    >
      {/* Заголовок */}
      <Typography variant="h6" fontWeight="bold">
        Подборки автора
      </Typography>
    </Box>
  );
};

export default PublicationsHeader;
