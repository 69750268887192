import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Container, Box, Typography, Button, Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
 } from "@mui/material";
import ChannelEditModal from "../components/AdminPage/ChannelEditModal";
import channelLogo from "../components/AdminPage/channel.png";
import ChannelPublications from "../components/AdminPage/ChannelPublications";
//import ChannelLogoEdit from "../components/AdminPage/ChannelLogoEdit";
import api, { updateChannel } from "../api";

const AdminPage = () => {
  const theme = useTheme(); // Получаем тему из контекста MUI
  const channelId = 2; // ID источника
  const [channel, setChannel] = useState(null);
  const [loadingChannel, setLoadingChannel] = useState(true);
  const [isLogoEditModalOpen, setLogoEditModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false); // Состояние для модального окна редактирования
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);


  useEffect(() => {
    setLoadingChannel(true);
    api
      .get(`/api/v1/sources/${channelId}/`)
      .then((response) => setChannel(response.data))
      .catch((err) => console.error("Ошибка при загрузке канала:", err))
      .finally(() => setLoadingChannel(false));
  }, [channelId]);

  const handleLogoUpload = async (file) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("favicon", file);

    setUploading(true);
    setError(null);

    try {
      // Отправляем запрос для обновления фавиконки
      await updateChannel(channelId, formData);
      // Получаем обновленные данные канала
      const response = await api.get(`/api/v1/sources/${channelId}/`);
      setChannel(response.data);
      setLogoEditModalOpen(false); // Закрываем модальное окно
    } catch (err) {
      console.error("Ошибка при загрузке логотипа:", err.message || err);
      setError("Не удалось загрузить логотип. Попробуйте снова.");
    } finally {
      setUploading(false);
    }
  };

  const isEditable = channel?.is_editable ?? false;

  return (
    <Box sx={{ marginBottom: "20px" }}>
      <Container maxWidth="lg">
        {loadingChannel ? (
          <Box display="flex" justifyContent="center" mt={4}>
            <Skeleton variant="rectangular" width="100%" height={120} />
          </Box>
        ) : channel ? (
          <Box>
            <Box display="flex" alignItems="center" mb={4} mt={4}>
              {isEditable ? (
                <Box
                  component="img"
                  src={channel.favicon || channelLogo}
                  alt={channel.name || "Канал"}
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: 3,
                    cursor: "pointer",
                  }}
                  onClick={() => setLogoEditModalOpen(true)}
                />
              ) : (
                <Box
                  component="img"
                  src={channel.favicon || channelLogo}
                  alt={channel.name || "Канал"}
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: "50%",
                    objectFit: "cover",
                    marginRight: 3,
                  }}
                />
              )}
              <Box>
                <Typography fontSize="18px" fontWeight="bold">
                  {channel.name || "Название канала"}
                </Typography>
                <Typography fontSize="14px">
                  {channel.description || "Описание канала"}
                </Typography>
                {isEditable && (
                  <Button
                    onClick={() => setEditModalOpen(true)}
                    sx={{
                      mt: 1,
                      fontSize: "12px",
                      bgcolor: (theme) =>
                        theme.palette.mode === "light" ? "#E0E0E0" : "#333333",
                      padding: "0px 10px",
                      color: (theme) =>
                        theme.palette.mode === "light" ? "black" : "white",
                      "&:hover": {
                        bgcolor: (theme) =>
                          theme.palette.mode === "light"
                            ? "#d6d6d6"
                            : "#4d4d4d",
                      },
                    }}
                  >
                    Редактировать
                  </Button>
                )}
              </Box>
            </Box>
            <ChannelPublications
              channelId={channelId}
              theme={theme}
              isEditable={isEditable}
            />
          </Box>
        ) : (
          <Typography color="error" textAlign="center" mt={4}>
            Ошибка при загрузке канала.
          </Typography>
        )}

         {/* Модальное окно изменения фавиконки */}
         <Dialog open={isLogoEditModalOpen} onClose={() => setLogoEditModalOpen(false)}>
          <DialogTitle>Загрузить новую фавиконку</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
              <Typography variant="body1">
                Выберите изображение для новой фавиконки:
              </Typography>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleLogoUpload(e.target.files[0])}
                disabled={uploading}
              />
              {uploading && (
                <Skeleton
                  variant="rectangular"
                  width={80}
                  height={10}
                  animation="wave"
                />
              )}
              {error && <Typography color="error">{error}</Typography>}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setLogoEditModalOpen(false)} disabled={uploading}>
              Отмена
            </Button>
          </DialogActions>
        </Dialog>
        {isEditModalOpen && (
          <ChannelEditModal
            open={isEditModalOpen}
            onClose={() => setEditModalOpen(false)}
            channel={channel}
            onSave={(updatedChannel) => setChannel(updatedChannel)}
          />
        )}       
      </Container>
    </Box>
  );
};

export default AdminPage;
