import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Skeleton,
  Grid,
  Modal,
  Button,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import PublicationsHeader from "./PublicationsHeader";
import NewsCard from "../NewsCard";
import api, { deleteNews } from "../../api";

const ChannelPublications = ({ channelId, theme, isEditable }) => {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNewsId, setSelectedNewsId] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const fetchPublications = useCallback(() => {
    setLoading(true);
    api
      .get(`/api/v1/news/`, {
        params: { source: channelId, ordering: "-publication_at" },
      })
      .then((response) => setPublications(response.data.results))
      .catch((err) => console.error("Ошибка при загрузке публикаций:", err))
      .finally(() => setLoading(false));
  }, [channelId]);

  useEffect(() => {
    fetchPublications();
  }, [fetchPublications]);

  const handleDeleteNews = async () => {
    try {
      await deleteNews(selectedNewsId);
      setPublications((prev) =>
        prev.filter((pub) => pub.id !== selectedNewsId)
      );
      setModalOpen(false);
    } catch (err) {
      console.error("Ошибка при удалении новости:", err);
    }
  };

  const openDeleteModal = (newsId) => {
    setSelectedNewsId(newsId);
    setModalOpen(true);
  };

  const closeDeleteModal = () => {
    setModalOpen(false);
    setSelectedNewsId(null);
  };

  return (
    <Box>
      <PublicationsHeader />
      {loading ? (
        <Grid container spacing={3} mt={2}>
          {Array.from({ length: 6 }).map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Skeleton variant="rectangular" height={360} />
            </Grid>
          ))}
        </Grid>
      ) : publications.length > 0 ? (
        <Grid container spacing={3} mt={2}>
          {publications.map((publication) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={publication.id}
              sx={{
                "@media (min-width: 400px) and (max-width: 600px)": {
                  flexBasis: "50%",
                  maxWidth: "50%",
                },
                "@media (min-width: 800px)": {
                  flexBasis: "33.33%",
                  maxWidth: "33.33%",
                },
                "@media (min-width: 1100px)": {
                  flexBasis: "25%",
                  maxWidth: "25%",
                },
              }}
            >
              <Box sx={{ position: "relative" }}>
                {isEditable && (
                  <IconButton
                    onClick={() => openDeleteModal(publication.id)}
                    sx={{
                      position: "absolute",
                      top: 8,
                      left: 8,
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "white",
                      "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.9)" },
                      zIndex: 2,
                    }}
                  >
                    <Delete />
                  </IconButton>
                )}
                {publication.category?.name && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: publication.main_image ? '53%' : "auto",
                      top: publication.main_image ? "auto" : 8,
                      right: 8,
                      backgroundColor: "rgba(0, 0, 0, 0.7)",
                      color: "white",
                      padding: "4px 8px",
                      fontSize: "0.75rem",
                      fontWeight: "bold",
                      borderRadius: "4px",
                      zIndex: 1,
                    }}
                  >
                    {publication.category.name}
                  </Box>
                )}
                <NewsCard
                  item={{
                    title: publication.title,
                    summary: publication.summary || publication.text,
                    main_image: publication.main_image,
                    category: publication.category?.name,
                  }}
                  time={publication.publication_at}
                  theme={theme}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Typography textAlign="center" mt={4}>
          Публикаций пока нет.
        </Typography>
      )}

      <Modal open={isModalOpen} onClose={closeDeleteModal}>
        <Box
          sx={{
            width: 300,
            bgcolor: "white",
            borderRadius: 2,
            p: 3,
            mx: "auto",
            mt: "20%",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" mb={2}>
            Действительно ли желаете удалить новость?
          </Typography>
          <Box display="flex" justifyContent="space-between">
            <Button variant="contained" onClick={handleDeleteNews}>
              Да
            </Button>
            <Button variant="outlined" onClick={closeDeleteModal}>
              Отмена
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChannelPublications;
