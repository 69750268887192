import React, { useState } from 'react';
import AuthModal from './AuthModal';
import { IconButton} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

const AuthButton = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    return (
        <>
            <IconButton onClick={toggleModal} color="inherit">
                <AccountCircle fontSize="inherit" />
            </IconButton>
            {isModalOpen && <AuthModal onClose={toggleModal} />}
        </>
    );
};

export default AuthButton;