import axios from 'axios';
import Cookies from 'js-cookie';

const api = axios.create({
  baseURL: 'https://zn.by/',
  withCredentials: true,
});


api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = Cookies.get('refresh-token');
      if (refreshToken) {
        try {
          const { data } = await api.post('/api/auth/token/refresh/', { refresh: refreshToken });
          Cookies.set('access-token', data.access, { secure: true, sameSite: 'Strict' });
          originalRequest.headers['Authorization'] = `Bearer ${data.access}`;
          return api(originalRequest);
        } catch (refreshError) {
          Cookies.remove('access-token');
          Cookies.remove('refresh-token');
          return Promise.reject(refreshError);
        }
      }
    }

    return Promise.reject(error);
  }
);


export const loginUser = (data) => api.post('/api/auth/login/', data);
export const registerUser = (data) => api.post('/api/auth/', data);
export const resetPassword = (email) => api.post('/api/auth/password-reset/', { email });

export const AuthUser = (data) => api.post('/api/auth/', data);
export const AuthUserVerifyEmail = (data) => api.post("/api/auth/verify/", data)



export const logoutUser = async () => {
  const response = await api.post('/api/auth/logout/');
  return response.data;
};

export const changePassword = async ({ new_password1, new_password2 }) => {
  const response = await api.post('/api/auth/password/change/', { new_password1, new_password2 });
  return response.data;
};

export const refreshToken = async (refresh) => {
  const response = await api.post('/api/auth/token/refresh/', { refresh });
  return response.data;
};

export const getUser = async () => {
  const response = await api.get('/api/auth/user/');
  return response.data;
};

export const updateUser = async (userData, method = 'PATCH') => {
  const response = await api({
    method: method,
    url: '/api/auth/user/',
    data: userData,
  });
  return response.data;
};

export const updateChannelLogo = async (channelId, file) => {
  const formData = new FormData();
  formData.append("favicon", file);

  const response = await api({
    method: "PATCH",
    url: `/api/v1/sources/${channelId}/logo/`, // Убедитесь, что этот путь соответствует вашему API
    data: formData,
  });

  return response.data;
};


export const updateChannel = async (channelId, channelData, method = "PATCH") => {
  const isFormData = channelData instanceof FormData;

  const response = await api({
    method: method,
    url: `/api/v1/sources/${channelId}/`,
    data: channelData,
    headers: isFormData
      ? { "Content-Type": "multipart/form-data" }
      : { "Content-Type": "application/json" },
  });

  return response.data;
};

export const deleteNews = async (newsId) => {
  await api.delete(`/api/v1/news/${newsId}/`);
};


export const verifyToken = async (token) => {
  await axios.post('/api/token/verify/', { token });
};


export const authenticateUser = async (data) => {
  try {
    const response = await api.post('/login/', data);
    return response.data; // Успешная аутентификация, возвращаем данные пользователя
  } catch (error) {
    if (error.response && error.response.data) {
      // Если от API пришёл объект с ошибками, возвращаем его
      throw error.response.data; 
    } else {
      // Если произошла ошибка на уровне сети или сервера, выводим общее сообщение
      throw new Error('Произошла ошибка при подключении к серверу');
    }
  }
};


export default api;
