import React, { useState, useEffect } from "react";
import { IconButton, Avatar, Skeleton } from "@mui/material";
import UserMenu from "./UserMenu";
import { useUser } from "../../context/UserContext";
import AuthButton from "./Auth/AuthButton";
import { useNavigate } from "react-router-dom";

const UserPanel = () => {
  const { user, loading, logout } = useUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const [isAvatarValid, setIsAvatarValid] = useState(true);

  // Извлечение вложенного объекта пользователя
  const userData = user?.user || user;

  console.log("Данные пользователя:", userData);

  // Генерация URL для аватара (если есть)
  const avatarSrc = userData?.avatar
    ? userData.avatar.startsWith("http")
      ? decodeURIComponent(userData.avatar)
      : `https://example.com${decodeURIComponent(userData.avatar)}`
    : null;

  // Проверка доступности аватара
  useEffect(() => {
    if (avatarSrc) {
      const img = new Image();
      img.src = avatarSrc;
      img.onload = () => setIsAvatarValid(true);
      img.onerror = () => setIsAvatarValid(false);
    }
  }, [avatarSrc]);

  const finalAvatarSrc = isAvatarValid ? avatarSrc : null;

  // Генерация инициалов
  const getInitials = () => {
    if (!userData) return "?";
    const firstNameInitial = userData.first_name?.[0]?.toUpperCase() || "";
    const lastNameInitial = userData.last_name?.[0]?.toUpperCase() || "";
    return `${firstNameInitial}${lastNameInitial}` || userData.username?.[0]?.toUpperCase() || "?";
  };

  // Обработчик выхода
  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      setAnchorEl(null);
    } catch (error) {
      console.error("Ошибка выхода:", error);
    }
  };

  // Загрузка
  if (loading) {
    return (
      <Skeleton
        variant="circular"
        width={35}
        height={35}
        animation="wave"
        sx={{ margin: 1 }}
      />
    );
  }

  // Основной рендер
  return userData ? (
    <>
      <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} color="inherit">
        {finalAvatarSrc ? (
          <Avatar
            src={finalAvatarSrc}
            alt={userData.username || "Аватар"}
            sx={{ width: 35, height: 35 }}
          />
        ) : (
          <Avatar
            sx={{
              width: 35,
              height: 35,
              bgcolor: "primary.main",
              color: "white",
            }}
          >
            {getInitials()}
          </Avatar>
        )}
      </IconButton>
      <UserMenu
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        handleLogout={handleLogout}
      />
    </>
  ) : (
    <AuthButton />
  );
};

export default UserPanel;
