import React, { createContext, useState, useEffect, useContext } from "react";
import api from "../api";
import Cookies from "js-cookie";


const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const refreshToken = Cookies.get("refresh-token");

        // Обновление токена доступа
        if (refreshToken) {
          const { data } = await api.post("/api/auth/token/refresh/", {
            refresh: refreshToken,
          });
          Cookies.set("access-token", data.access, { secure: true, sameSite: "Strict" });
        }

        const accessToken = Cookies.get("access-token");
        if (accessToken) {
          // Получение данных пользователя
          const response = await api.get("/api/auth/user/");
          setUser(response.data);
        } else {
          throw new Error("Токен доступа отсутствует или недействителен");
        }
      } catch (error) {
        console.error("Ошибка загрузки данных пользователя:", error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  

  const login = async (email, password) => {
    try {
      const { data } = await api.post("/api/auth/login/", { email, password });
      Cookies.set("access-token", data.access, { secure: true, sameSite: "Strict" });
      Cookies.set("refresh-token", data.refresh, { secure: true, sameSite: "Strict" });
      setUser(data.user);
    } catch (error) {
      console.error("Ошибка при входе:", error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await api.post("/api/auth/logout/");
      Cookies.remove("access-token");
      Cookies.remove("refresh-token");
      setUser(null); // Сбрасываем состояние пользователя
    } catch (error) {
      console.error("Ошибка при выходе:", error);
    }
  };
  

  return (
    <UserContext.Provider value={{ user, setUser, loading, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
