import React from "react";
import { Card, Box, Typography } from "@mui/material";
import NewsImage from "./NewsCard/NewsImage";
import NewsFooter from "./NewsCard/NewsFooter";
import { timeAgo } from "../utils/timeAgoUtils";

const NewsCard = ({ item, time, theme }) => {
  const hasImage = Boolean(item?.main_image);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "10px",
        bgcolor: `${
          theme.palette.mode === "dark" ? "#58585854" : "#ebebebff"
        }`,
        border: `1px solid ${
          theme.palette.mode === "dark" ? "#b0b0b054" : "transparent"
        }`,
        position: "relative",
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
          boxShadow:
            theme.palette.mode === "dark"
              ? "0px 4px 12px rgba(180, 180, 180, 0.774)"
              : "0px 4px 12px rgba(0, 0, 0, 0.39)",
        },
        width: "100%",
        height: "360px", // Фиксированная высота для десктопа и планшета
        "@media (max-width: 600px)": {
          height: "auto", // Динамическая высота на мобильных устройствах
        },
      }}
    >
      {/* Изображение */}
      {hasImage && (
        <NewsImage
          image={item.main_image}
          category={item.category || { name: "Без категории" }}
          sx={{
            width: "100%",
            height: "180px", // Фиксированная высота изображения
            objectFit: "cover", // Сохраняем пропорции
            "@media (max-width: 600px)": {
              height: "150px", // Уменьшение высоты на мобильных
            },
          }}
        />
      )}

      {/* Контент карточки */}
      <Box
        sx={{
          padding: "10px",
          flex: 1,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        {/* Заголовок */}
        <Typography
          variant="h6"
          sx={{
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "1.3",
            mb: 1,
            color: theme.palette.mode === "dark" ? "#ffffff" : "#000000",
            whiteSpace: "normal", // Позволяем тексту переноситься
            wordBreak: "break-word", // Разрываем длинные слова
          }}
        >
          {item?.title || "Без названия"}
        </Typography>

        {/* Описание */}
        {item?.summary && (
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              color: theme.palette.mode === "dark" ? "#d3d3d3" : "#6f6f6f",
              lineHeight: "1.5",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3, // Ограничиваем текст тремя строками
              WebkitBoxOrient: "vertical",
              mb: 1,
            }}
          >
            {item.summary}
          </Typography>
        )}

        {/* Время публикации */}
        <NewsFooter publicationDate={timeAgo(time)} />
      </Box>
    </Card>
  );
};

export default NewsCard;
